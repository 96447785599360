.about {
    padding-top: 180px;
    padding-bottom: 180px;
    position: relative;
    @include mq($until: sm) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            font-size: rem(40px);
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        h3 {
            text-align: center;
            color: $color-extra;
            font-size: rem(26px);
            font-weight: 400;
            text-transform: none;
            margin-top: 20px;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 100px;
            gap: 80px;
            @include mq($until: 1800px) {
                gap: 40px;
            }
            @include mq($until: xl) {
                grid-template-columns: repeat(3, 1fr);
                gap: 80px;
            }
            @include mq($until: md) {
                column-gap: 40px;
            }
            @include mq($until: sm) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include mq($until: xs) {
                grid-template-columns: 1fr;
            }
            &__item {
                row-gap: 40px;
                padding: 0;
                border: unset;
                overflow: unset;
                &:hover {
                    h4 {
                        color: $color-extra;
                    }
                }
                img {
                    border-radius: 0;
                    height: 180px;
                    width: auto;
                    @include mq($until: 1800px) {
                        height: 160px;
                    }
                    @include mq($until: md) {
                        height: 140px;
                    }
                    @include mq($until: sm) {
                        height: 100px;
                    }
                }
                &-content {
                    h4 {
                        font-size: rem(20px);
                        font-weight: 700;
                        transition: ease 0.3s all;
                    }
                }
            }
        }
    }
}
