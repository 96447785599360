.quality {
    padding-top: 180px;
    padding-bottom: 80px;
    position: relative;
    min-height: 120vh;
    background-size: cover;
    background-position: bottom center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mq($until: md) {
        padding-top: 80px;
        padding-bottom: 120px;
        align-items: center;
        min-height: unset;
        &:after {
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
    @include mq($until: sm) {
        padding-bottom: 80px;
    }
    &__content {
        position: relative;
        z-index: 1;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        @include mq($until: md) {
            text-align: center;
        }
        h2 {
            font-size: rem(40px);
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        h3 {
            color: $color-extra;
            font-size: rem(26px);
            font-weight: 400;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 60px;
            padding-left: 140px;
            @include mq($until: md) {
                padding-left: 0;
            }
        }
        @include mq($until: 1380px) {
            p {
                max-width: 500px;
            }
        }
        @include mq($until: md) {
            p {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .button {
            margin-top: 60px;
            gap: 10px;
            display: flex;
            align-items: center;
            background-color: $color-extra;
            svg {
                width: 29px;
                height: auto;
                fill: #fff;
            }
            span {
                color: #fff;
            }
        }
    }
    &__circles {
        display: flex;
        gap: 30px;
        margin-top: 100px;
        position: relative;
        z-index: 1;
        @include mq($until: md) {
            flex-wrap: wrap;
            justify-content: center
        }
        @include mq($until: sm) {
            margin-top: 80px;
        }
        &--single {
            width: 19.0589vw;
            height: 19.0589vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-position: center;
            background-size: cover;
            border: 0.75vw solid rgba(255, 255, 255, 0.9);
            color: #fff;
            font-weight: 700;
            font-size: 1.5vw;
            line-height: 2vw;
            text-transform: uppercase;
            text-align: center;
            box-shadow: 0 3px 40px rgba(0, 0, 0, 0.33);
            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
            @include mq($until: md) {
                width: 33vw!important;
                height: 33vw!important;
                border: 1.25vw solid rgba(255, 255, 255, 0.9);
                font-size: 3vw;
                line-height: 4vw;
                top: unset!important;
            }
            @include mq($until: xs) {
                width: 60vw!important;
                height: 60vw!important;
                border: 2vw solid rgba(255, 255, 255, 0.9);
                font-size: 5vw;
                line-height: 6vw;
                top: unset!important;
            }
            &:nth-child(2) {
                width: 17.7135vw;
                height: 17.7135vw;
                top: 50px;
            }
            &:nth-child(3) {
                width: 16.817vw;
                height: 16.817vw;
                top: 40px;
            }
            &:nth-child(4) {
                width: 15.6956vw;
                height: 15.6956vw;
            }
        }
    }
}
