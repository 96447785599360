.projects {
    padding-top: 180px;
    padding-bottom: 180px;
    position: relative;
    min-height: 100vh;
    background-size: cover;
    background-position: top center;
    @include mq($until: md) {
        min-height: unset;
        padding-top: 80px;
        padding-bottom: 120px;
        justify-content: center;
        display: flex;
        &:after {
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        @include mq($until: md) {
            text-align: center;
        }
        h2 {
            font-size: rem(40px);
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        h3 {
            color: $color-extra;
            font-size: rem(26px);
            font-weight: 400;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 60px;
            padding-left: 140px;
            @include mq($until: md) {
                padding-left: 0;
            }
        }
        @include mq($until: 1380px) {
            p {
                max-width: 500px;
            }
        }
        @include mq($until: md) {
            p {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .button {
            margin-top: 60px;
            gap: 10px;
            display: flex;
            align-items: center;
            background-color: $color-extra;
            @include mq($until: md) {
                margin-left: auto;
                margin-right: auto;
            }
            svg {
                width: 29px;
                height: auto;
                fill: #fff;
            }
            span {
                color: #fff;
            }
        }
    }
}
