.footer {
  background-color: $color-footer;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  row-gap: 40px;
  column-gap: 40px;
  span, p, a, div, svg path {
    color: #fff;
    fill: #fff;
  }
  p {
    margin: 0;
  }
  svg {
    width: 30px;
    height: auto;
    max-width: unset;
  }
  .title {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .logo {
    img {
      height: auto;
    }
  }
  .social {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .social svg {
    transition: ease 0.3s all;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .form span {
    color: $paragraph-color;
  }
  .form {
    background-color: #fff;
    padding: 40px;
    max-width: 700px;
    margin-bottom: 0;
    @include mq($until: lg){
      max-width: 100%;
    }
  }
  .nav {
    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center
    }
    &.space {
      .header__menuList {
        width: 100%;
        justify-content: space-between;
        column-gap: 10px;
      }
      .header__menuLink {
        padding: 20px 0px;
      }    
    }
    .header__menuList {
      padding: 0;
    }
    .header__menuLink:active, .header__menuLink--active, .header__menuLink--open, .header__menuLink:hover {
      color: #fff;
      span {
        color: #fff;
      }
    }
  }
  .block {
    padding: 40px;
    background: lighten($color-footer, 4);
    transition: ease 0.3s all;
  }
  .contact {
    .list {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      a {
        display: flex;
        column-gap: 15px;
        align-items: center;
      }
    }
  }
  &.icons-bg {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      background: lighten($color-footer, 4);
      transition: ease 0.3s all;
    }
    a:hover svg {
      background-color: $color-extra;
    }
    .block svg {
      background: lighten($color-footer, 10);
    }
  }
  &.icons-border {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      border: 1px solid lighten($color-footer, 10);
      transition: ease 0.3s all;
    }
    a:hover svg {
      border-color: $color-extra;
    }
  }



  &.footer01 {
    justify-content: space-between;
    &.grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @include mq($until: xl){
        grid-template-columns: 1fr 1fr;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    @include mq($until: xl){
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
  }



  &.footer02 {
    display: grid;
    grid-template-columns: minmax(200px, 350px) minmax(200px, 350px) auto;
    @include mq($until: xl){
      grid-template-columns: 1fr 1fr;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include mq($until: xl){
        justify-content: flex-start;
      }  
      .title {
        margin-bottom: 0;
      }
    }
  }

  &.footer03 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    align-items: end;
    position: relative;
    z-index: 9;
    background-color: rgba($color-footer, 1);
    column-gap: 2vw;
    @include mq($until: xl){
      align-items: start;
    }
    @include mq($until: md){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    .contact {
      row-gap: 40px;
      column-gap: 40px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      justify-content: start;
      max-width: 600px;
      @include mq($until: xl){
        grid-template-columns: 1fr;
        justify-items: start;
      }
      @include mq($until: md){
        //order: 2;
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
        max-width: 100%;
        justify-items: unset;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
      .address {
        text-align: right;
        @include mq($until: xl){
          text-align: left;
        }
      }
      .list {
        display: unset;
        a {
          justify-content: center;
          padding:  10px 20px;
        }
      }
    }
    .social {
      justify-content: flex-end;
      .title {
        font-weight: 400;
      }
    }
    .nav .header__menuList {
      flex-wrap: wrap;
      @include mq($until: md){
        flex-direction: row;
        justify-content: flex-start;
        .header__menuItem {
          width: unset;
        }
      }
      @include mq($until: xs){
        flex-direction: column;
        .header__menuItem {
          width: 100%;
        }
      }
      .header__menuLink {
        padding: 5px 10px;
        font-size: 18px;
        span {
          font-size: 18px;
        }
      }
    }
    .form {
      position: absolute;
      bottom: 130px;
      max-width: 39vw;
      //margin-top: -200px;
      background: linear-gradient(0deg, #F5F7F7 0%, #F5F7F7 33%, rgba(245, 247, 247, 0.87) 100%);
      border-radius: 8px;
      @include mq($until: xl){
        max-width: 100%;
        position: relative;
        bottom: unset;
      }
      &__group {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: 1700px){
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
        @include mq($until: md){
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: unset;
        }
        @include mq($until: sm){
          grid-template-columns: 1fr;
        }  
        .form__row {
          margin-top: 0;
          @include mq($until: 1700px){
            &:nth-child(1) {
              grid-area: 1 / 1 / 2 / 3; 
            }
            &:nth-child(2) {
              grid-area: 1 / 3 / 2 / 5; 
            }
            &:nth-child(3) {
              grid-area: 2 / 1 / 3 / 5;
            }
          }
          @include mq($until: md){
            &:nth-child(1) {
              grid-area: unset; 
            }
            &:nth-child(2) {
              grid-area: unset; 
            }
            &:nth-child(3) {
              grid-area: unset; 
            }
          }
        }
      }
      &__input {
        border-color: #D5D2D0;
      }
      textarea {
        height: 150px;
      }
      .button {
        background-color: #D5D2D0;
        color: $color-main;
        &:hover{
          color: #fff;
          background-color: $color-extra;
        }
      }
      &__border {
        background-color: $color-extra;
      }
      &__input:focus ~ .form__label {
        color: $color-extra;
      }
      .submitSection {
        margin-top: 20px;
        display: flex;
        column-gap: 10px;
        row-gap: 20px;
        @include mq($until: xl){
          flex-direction: column;
        }
        @include mq($until: md){
          flex-direction: row;
        }
        @include mq($until: sm){
          flex-direction: column;
        }
      }
      &__rodo {
        margin: 0;
        gap: 20px;
        flex: 1;
        span {
          font-size: 10px;
        }
        .checker {
          background-color: #D5D2D0;
        }
        a {
          color: $paragraph-color;
        }
      }
    }
    @include mq($until: md){
      .form {
        order: 2;
      }
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      @include mq($until: md){
        align-items: flex-start;
      }
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer04 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    @include mq($until: xl){
      grid-template-columns: auto 400px;
    }
    @include mq($until: lg){
      grid-template-columns: 1fr;
    }
    .block:nth-child(2),.block:nth-child(3) {
      background-color: transparent;
    }
    @include mq($until: sm){
      .block:nth-child(3) {
        background: lighten($color-footer, 4);
      }
      .block:nth-child(4) {
        background-color: transparent;
      }
    }
    .left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: 40px;
      &.blocks {
        gap: 0;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer05 {
    flex-direction: column;
    .top {
      display: grid;
      grid-template-columns: minmax(200px, 25%) auto;
      align-items: center;
      @include mq($until: md){
        grid-template-columns: 1fr;
        row-gap: 40px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      column-gap: 40px;  
      padding-top: 60px;
      border-top: 1px solid lighten($color-main, 3);
      @include mq($until: xl){
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
      &.grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl){
          grid-template-columns: 1fr 1fr;
        }
        @include mq($until: sm){
          grid-template-columns: 1fr;
        }  
      }
      .social {
        margin-top: 20px;
      }
    }
  }
  &.footer06 {
    justify-content: space-between;
    @include mq($until: lg){
      flex-direction: column;
    }
    .left { 
      row-gap: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq($until: lg){
        flex-direction: column-reverse;
      }  
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 40px;
        @include mq($until: lg){
          flex-direction: column;
          align-items: flex-start;
          row-gap: 40px;
        }  
        .title {
          margin: 0;
        }
      }
      .nav {
        border-top: 1px solid lighten($color-main, 3);
        padding-top: 20px;
        @include mq($until: lg){
          border-top: none;
          border-bottom: 1px solid lighten($color-main, 3);
          padding-top: 0;
          padding-bottom: 40px;
        }
        .header__menuList {
          column-gap: 40px;
        }
      }
    }
    @include mq($until: 380px){
      .social {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 15px;
      }
    }
  }
}


.footer-experience {
  min-height: 120vh;
  background-size: cover;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  @include mq($until: xl){
    background-position: 0;
  }
  @include mq($until: md){
    min-height: 90vh;
    background-position: bottom center;
  }
  @include mq($until: sm){
    min-height: 60vh;
  }
  &__info {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
    max-width: 50%;
    background: linear-gradient(270deg, rgba(194,57,46,0) 0%, rgba(194,57,46,0.40) 25%, rgba(192,56,45,0.86) 50%, rgba(112,33,26,0.86) 75%, #621d17 100%);
    @include mq($until: xl){
      max-width: 100%;
    }
    h2 {
      color: #fff;
      font-size: rem(35px);
      margin: 0;
      text-transform: none;
      @include mq($until: md){
        font-size: rem(30px);
      }
      @include mq($until: xs){
        font-size: rem(24px);
      }
      @include mq($until: 380px){
        font-size: rem(22px);
      }
    }
  }
  &__circle {
    position: absolute;
    width: 19.0589vw;
    height: 19.0589vw;
    top: 14vw;
    right: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-color: $color-main;
    border: 0.75vw solid rgba(255, 255, 255, 0.85);
    color: #fff;
    font-weight: 700;
    font-size: 3vw;
    line-height: 3vw;
    text-transform: uppercase;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    @include mq($until: xl){
      top: unset;
      bottom: 80px;
    }
    @include mq($until: md){
      width: 28vw;
      height: 28vw;
      border: 1.25vw solid rgba(255, 255, 255, 0.9);
      font-size: 4vw;
      line-height: 4vw;
      bottom: 220px;
    }
    span {
      color: #fff;
      text-transform: none;
      font-size: 2.1vw;
      @include mq($until: md){
        font-size: 3vw;
      }  
    }
  }
}


.footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    @include mq($until: xs){
      flex-direction: column;
      row-gap: 10px;
    }
    a, span, p, div {
      color: #4E5360;
      font-size: 14px;
    }
    a:hover {
      color: $color-main;
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}
