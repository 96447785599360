.features {
    padding-bottom: 180px;
    position: relative;
    margin-top: -240px;
    @include mq($until: md) {
        padding-bottom: 80px;
    }
    @include mq($until: sm) {
        padding-bottom: 0;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
            gap: 25px;
            @include mq($until: xs) {
                grid-template-columns: repeat(1, 1fr);
            }
            &__item {
                background-color: $color-main;
                padding: 0;
                row-gap: 0;
                border: 8px solid #fff;
                border-radius: 15px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                @include mq($until: xs) {
                    grid-template-columns: repeat(1, 1fr);
                }
                img {
                    border-radius: 0;
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 3/2;
                    height: auto;
                }
                &-content {
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    column-gap: 10px;
                    @include mq($until: 1280px) {
                        justify-content: center
                    }
                    p {
                        color: #fff;
                        font-size: rem(20px);
                        font-weight: 700;
                        @include mq($until: 1600px) {
                            max-width: 140px;
                        }
                        @include mq($until: 1280px) {
                            text-align: center;
                        }
                    }
                    &:after {
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Ccircle cx='20' cy='20' r='20' stroke='none'/%3E%3Ccircle cx='20' cy='20' r='19' fill='none'/%3E%3C/g%3E%3Cpath d='M-13986.5,1113.848l10.279,9.778-10.279,9.778' transform='translate(14002.748 -1103.712)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 40px;
                        height: 40px;
                        @include mq($until: 1280px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
