.article {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding-xl;

	&.reverse {
		flex-direction: row-reverse;
	}

	&__text, &__image, &__slider {
		width: 50%;
		position: relative;
	}


	&__image, &__slider {

		height: 30vw;

		img {
			border-radius: 10px;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

  	}

	&__slider {
		position: relative;

		& *:not(.article__slider-arrow) {
			width: 100%;
			height: 100%;
		}

	}

	&__slider-arrow {
		position: absolute;
		bottom: 15%;
		z-index: 1;
		border: none;
		width: 40px;
		height: 40px;
		background-color: #{$color-main}8c;
		border-radius: 50%;
		transition: all .4s;
		background-size: 30%;
		background-repeat: no-repeat;

		&_next {
			right: calc(100px - 40px);
			background-image: url('../../images/next.png');
			background-position: 56% center;
		}
		&_prev {
			right: calc(150px - 40px);
			background-image: url('../../images/prev.png');
			background-position: 44% center;
		}

		&:hover {
			background-color: $color-main;
		}
	}

	&__text {

		&_normal {
			margin-left: 5%;
		}

		&_reverse {
			margin-right: 5%;
		}
		&.center {
			text-align: center;
		}
		&.left {
			text-align: left;
		}
		&.right {
			text-align: right;
		}
		h4 {
			margin-bottom: 40px;
		}
	}

	.button {
		margin-top: 40px;
		min-width: 160px;
		display: inline-block;
		width: fit-content;

		/*&-normal {
			margin-right: auto;
		}

		&-reverse {
			margin-left: auto;
		}*/
	}

	@include mq($until: md) {

		flex-direction: column;
		margin-bottom: $padding-lg;

		&.reverse {
			flex-direction: column-reverse;
		}

		&__text, &__image, &__slider {
			width: 100%;
			margin: 0;
		}

		&__image, &__slider {
			height: 60vw;
			margin: 30px 0;
		}

		&__slider-arrow {

			&_next {
				right: calc(50px - 40px);
			}
			&_prev {
				right: calc(100px - 40px);
			}
		}
	}

}
