.icons-set {
  display: grid;
  gap: 1vw;
  justify-items: stretch;

  &__item {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 40px;
    width: 100%;
    height: 100%;
    margin: 0;
    transition: ease 0.3s all;
    //overflow: hidden;

    //border-radius: 10px;
    //border: 1px solid rgba($color-main, 0.1);
    //padding: 20px;

    &:hover {
      border-color: $color-main;
        p {
            color: $color-extra;
        }
    }

    img {
      height: 180px;
      width: auto;
      //width: 100px;
      //height: auto;
      transition: ease 0.3s background-color;
      @include mq($until: 1800px) {
        height: 160px;
      }
      @include mq($until: md) {
          height: 140px;
      }
      @include mq($until: sm) {
          height: 100px;
      }
    }
    p {
      font-size: rem(20px);
      font-weight: 700;
      margin-bottom: 0;
      transition: ease 0.3s color;
    }
    span {
      font-weight: 400;
      margin-top: 10px;
      transition: ease 0.3s color;
      display: block;
    }
    .button {
      margin-top: 15px;
    }
    

    &[data-hover]:not([data-hover*='fade']):hover {
      img {
        background: #fff;
      }
      p, span {
        color: #fff;
      }
    }
  }


  &__item-text {
      font-weight: 600;
      font-size: rem(16px);
  }

  @include mq($until: md) {
    &__item {
      img {
        width: 60px;
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-left .icons-set__item {
  flex-direction: row;
  align-items: center;
}
.icons-center .icons-set__item {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icons-right .icons-set__item {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & img {
    order: 1;
  }
}


.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @include mq($until: sm) {
    &--6 {
      grid-template-columns: 1fr;
    }
  }
}



.flipbox {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  aspect-ratio: 1;
  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  &:hover {
    .flipbox-inner {
      transform: rotateY(180deg);
    }
  }
  &-front, &-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &-back {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 10px;
    background-color: $color-main;
    padding: 20px;
    text-align: center;
    h4, span {
      color: #fff;
    }
  }
  
}