.realizations {
    padding-top: 180px;
    padding-bottom: 80px;
    position: relative;
    @include mq($until: xl) {
        padding-bottom: 0;
    }
    @include mq($until: md) {
        padding-top: 80px;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            font-size: rem(40px);
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        h3 {
            text-align: center;
            color: $color-extra;
            font-size: rem(26px);
            font-weight: 400;
            text-transform: none;
            margin-top: 20px;
        }
        p {
            text-align: center;
        }
        .gallery {
            margin-top: 100px;
            gap: 25px;
            &__link {
                border-radius: 10px;
            }
            /*img {
                width: 100%;
                border-radius: 10px;
            }
            .image {
                width: 100%;
            }*/
            .icon {
                font-size: rem(28px);
                font-weight: 700;
                text-align: center;
            }
        }
    }
}
