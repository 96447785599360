.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 10px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 0;
  align-self: flex-start;
  cursor: pointer;

  font-size:  $base-font-size;

  &:hover {
    color: $color-font-light;
    /*
    background-color: transparent;
    border-color: $color-main;
    */
  }

  &--xs {
    font-size: $base-font-size - 6;
  }
  &--sm {
    font-size: $base-font-size - 3;
  }
  &--lg {
    font-size: $base-font-size + 3;
  }
  &--xl {
    font-size: $base-font-size + 6;
  }

  &--extra {
    background-color: $color-extra;
    border-color: $color-extra;
  }
  &--black {
    background-color: #000;
    border-color: #000;
  }
  &--white {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    &:hover {
      color: #000;
    }
  }
  &--shadow {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  &--border {
    background-color: transparent;
    color: $color-main;
    border: solid 1px $color-main;
    &:hover {
      color: $color-main;
    }
  }
  &--radius {
    border-radius: 100px;
  }
  &--noradius {
    border-radius: 0;
  }

  &[data-hover*='white']:hover {
    color: $color-font-dark;
  }


  &--secondary {
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }
}
