.subsite {
    //background-color: #f9f8f8;
    .header {
        //box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
    }
    .hero {
        min-height: 560px;
        @include mq($until: md) {
            min-height: 360px;
        }
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        @include mq($until: md) {
            padding-top: 40px;
        }
        h1 {
            margin-bottom: 80px;
            text-align: center;
        }
        h2 {
            text-align: center;
            font-size: rem(40px);
            @include mq($until: sm) {
                font-size: rem(30px);
            }
        }
        h3 {
            text-align: center;
            color: $color-extra;
            font-size: rem(26px);
            font-weight: 400;
            text-transform: none;
            margin-top: 20px;
        }
    }
    .footer-experience .divider:before {
        background: linear-gradient(0deg, transparent 0%, #f9f8f8 100%)!important;
    }
    .footer03 {
        align-items: stretch;
        grid-template-columns: minmax(300px, auto) auto;
        @include mq($until: md){
            grid-template-columns: 1fr;
        }      
        & > div:last-of-type {
            justify-content: flex-end;
            align-items: center
        }
        .nav {
            width: 100%;
            .header__menuList {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                align-items: stretch;
                @include mq($until: sm){
                    grid-template-columns: repeat(2, 1fr);
                }
                @include mq($until: xs){
                    grid-template-columns: 1fr;
                }
            }
            .header__menuList .header__menuLink {
                padding: 15px;
                text-align: center;
                span {
                    display: inline;
                    @include mq($until: md){
                        display: none;
                    }
                }
            }
            .header__menuItem {
                background-color: rgba(255,255,255, 0.05);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
