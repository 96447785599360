.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  background-color: $color-header;
  column-gap: 40px;

  &--absolute {
    position: absolute;
    z-index: 3;
    width: 100%;
    border-bottom: none;
    background: rgba(255, 255, 255, 0.75);
    .header__menuLink {
      color: $color-main;
      &:hover, &--active {
        color: $color-extra;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      z-index: 99999;
      width: 100%;
      background: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(5px);

    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mq($until: md) {
      div:last-of-type {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }
    .mobile-call {
      display: none;
      padding: 10px;
      @include mq($until: md) {
        display: inline;
      }
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
      }
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    img {
      height: auto;
      min-width: 140px;
      @include mq($until: lg) {
        width: 13vw;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    column-gap: 30px;
    white-space: nowrap;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
    &--extra {
      background-color: $color-main;
      border-radius: 0 0 0 27px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      .header__menuLink {
        color: #fff;
        text-transform: unset;
        @include mq($until: lg) {
          font-size: rem(17px);
        }
        @include mq($until: md) {
          font-size: rem(19px);
        }
        &:hover {
          color: $color-extra;
        }
      }
      .header__contact {
        display: flex;
        column-gap: 10px;
      }
      .button {
        padding: 10px;
        &:first-of-type svg {
          margin-right: 10px;
        }
        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
        }
      }
    }
    @include mq($until: 1630px) {
      &--main .header__menuLink {
        white-space: normal;

      }
    }
  }


  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #000;
    padding: 20px 10px;
    display: block;
    font-weight: 500;
    font-size: rem(19px);
    text-transform: uppercase;
    text-align: center;
    span {
      font-weight: 500;
      font-size: rem(19px);
      text-transform: uppercase;
      text-align: center;
      transition: color 0.3s ease-in-out;
      @include mq($until: 1630px) {
        display: none;
      }
      @include mq($until: md) {
        display: inline;
        color: #fff;
        text-transform: unset;
      }
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover, &:active span, &--active span, &--open span, &:hover span {
      color: $color-extra;
    }
  }
}
