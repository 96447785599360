.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 120vh;
    display: flex;
    align-items: center;
    @include mq($until: md) {
        min-height: 90vh;
    }
    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        h1 {
            color: #fff;
            margin-bottom: 0;
            line-height: rem(80px);
            font-size: rem(80px);
            font-weight: 700;
            text-shadow: 0 3px 36px rgba(0, 0, 0, 0.58);
            @include mq($until: xl) {
                line-height: 6vw;
                font-size: 6vw;
            }
            @include mq($until: sm) {
                line-height: rem(42px);
                font-size: rem(42px);
            }
            &:nth-child(n+2) {
                margin-top: 0;
            }
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type {
                margin-top: 20px;
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
    .divider::before {
        height: 200px!important;
    }
}
